<template>
    <div class="page">
        <div class="page-title flex justify-between bg-white">
            <h1 class=" typography-h1 title">
                Pořadatelé
            </h1>
            <q-toggle v-if="showArchivedToggle" v-model="listArchived" class="q-mx-lg my-toggle" left-label :label="$t('label.show-archived')" />
        </div>
        <div class="wrapper">
            <div class="row">
                <div class="col-4 q-pr-md discounts-wrapper">
                    <q-btn
                        v-if="!createMode|| active || viewMode"
                        @click="showCreateCard"
                        class="my-btn create-card"
                        style="width: 100%"
                        label="Přidat pořadatele"
                        type="text"
                        color="black"
                        icon-right="svguse:/icons.svg#plus2px"
                    />
                    <CardPromoter class="skeleton" @delete="createMode=false" v-if="createMode && !active"/>
                    <div class="q-mb-md"></div>
                    <div
                        v-if="filteredPromoters.length"
                        v-for="promoter in filteredPromoters"
                        :key="promoter.id"
                        class="q-mb-md">
                        <CardPromoter
                            :class="{active: active && active.id === promoter.id}"
                            @click.native="setActive(promoter)"
                            :id="promoter.id"
                            @delete="del"
                            :title="promoter.title"/>
                    </div>
                </div>
                <div class="col-8">
                    <div v-if="filteredPromoters.length && !createMode && !viewMode"  class="empty">
                        <q-icon name="svguse:/icons.svg#empty" size="48px" class="block q-mb-md"/>
                        <span class="text-grey-6">{{$t('label.select-organizer')}}</span>
                    </div>
                    <div v-if="!filteredPromoters.length && !createMode && !viewMode"  class="empty">
                        <q-icon name="svguse:/icons.svg#empty" size="48px" class="block q-mb-md"/>
                        <span class="text-grey-6">{{$t('label.empty-organizer')}}</span>
                    </div>
                    <CardPromoterCreate @close="closeEdit" @create="createPromoter" v-if="createMode && !viewMode && !active"/>
                    <CardPromoterCreate @close="closeEdit" :content="active" @create="updatePromoter" v-if="viewMode && active"/>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardPromoter from "../components/Card/CardPromoter/CardPromoter";
import CardPromoterCreate from "../components/Card/CardPromoter/CardPromoterCreate";
export default {
    components: {CardPromoterCreate, CardPromoter},
    @cancellableFetch
    async created() {
        await this.fetchAll('settings');
        await this.fetchMany('promoters');
    },

    meta: {
        title: 'Pořadatelé',
    },
    data() {
        return {
            listArchived: false,
            createMode: false,
            active: null,
            viewMode: false,
        };
    },
    computed:{
        filteredPromoters() {
            return (this.promoters || []).filter(g => this.listArchived ? g.archived : !g.archived).reverse();
        },
    },
    methods:{
        setActive(item) {
            this.createMode = false
            this.viewMode = true;
            this.active = null
            setTimeout(()=>{
                this.active = item
                this.viewMode = true
            }, 0)
        },
        async updatePromoter(item){
            const transformed = {
                ...item.original,
                title: item.title,
                fullTitle: item.fullTitle,
                dic: item.dic,
                ico: item.ico,
            }
            const promoter = await this.update('promoters', transformed);
            this.updateIn(this.promoters, promoter);
            notifyOk(this.$t('notification.general_information-updated'));
            this.active=promoter

        },
        async createPromoter(item) {
            const transformed = {
                title:item.title,
                fullTitle:item.fullTitle,
                dic:item.dic,
                    ico:item.ico,
            }
            const promoter = await this.create('promoters', transformed);
            this.promoters.push(promoter);
            notifyOk(this.$t('notification.organizer_saved'));
            this.active=promoter
            this.createMode = false
            this.viewMode = true;
        },
        async del(promoter) {
            const {result, archived} = await this.delete('promoters', promoter);
            if (result === 'destroyed') {
                notifyOk(this.$t('notification.organizer_deleted'));
                this.deleteIn(this.promoters, promoter);
                this.createMode = false
                this.closeEdit()

            } else if (result === 'archived') {
                notifyOk("Sleva byla archivována.");
                promoter.archived = archived;
                this.updateIn(this.promoters, promoter);
                this.closeEdit()

            } else {
                debug.warn(`Unknown deletion result: '${result}'`);
            }
        },
        closeEdit() {
            this.active = null
            this.createMode = false
            this.viewMode = false;
        },
        showCreateCard(){
            this.active = null
            this.createMode = false
            this.viewMode = false;
            this.active = null;
            setTimeout(()=>{
                this.createMode = true
            }, 0)
        },
    },
    mounted() {
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 27 || e.code === 'Escape') {
                this.closeEdit();
            }
        });
    },
    beforeDestroy() {
        document.removeEventListener('keydown', (e) => {
            if (e.keyCode === 27 || e.code === 'Escape') {
                this.closeEdit();
            }
        });
    }

};
</script>

<style scoped lang="scss">
.page-title{
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;
    border-bottom: 1px solid $black-200;
    padding: 24px 16px;
    align-items: center;

}

.wrapper{
    overflow: hidden;
    position: relative;
    padding: 32px 16px 0;
}
.discounts-wrapper {
    height: calc(100vh -  137px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }
}
.empty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 188px;
    width: 100%;
    border: 1px solid #BABABA;
}
.create-card, .skeleton{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
}
</style>
